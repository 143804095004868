import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { deck } from '../shared/deck';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  deck = deck;
  randomCard: any;
  magicCard: string | undefined;
  gameStatus: any;
  gameRound = 0;
  startDeck = new Array;
  magicDeck = new Array;
  groupOne = new Array;
  groupTwo = new Array;
  groupThree = new Array;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.startingDeck();
  }

  // Computer chooses 21 random cards from a full deck
  startingDeck(): void {
    for (let i = 0; i < 21; i++) {
      this.randomCard = deck[Math.floor(Math.random() * deck.length)];
      const index = deck.indexOf(this.randomCard);
      deck.splice(index, 1);
      this.startDeck.push(this.randomCard);
    }
    this.gameStatus = 'Choose a card';
  }

  // Computer deals cards in magic pattern
  dealDeck(): void {
    this.gameStatus = 'Choose a group';
    this.groupOne.push(
      this.startDeck[0],
      this.startDeck[5],
      this.startDeck[6],
      this.startDeck[11],
      this.startDeck[12],
      this.startDeck[17],
      this.startDeck[18],
    );
    this.groupTwo.push(
      this.startDeck[1],
      this.startDeck[4],
      this.startDeck[7],
      this.startDeck[10],
      this.startDeck[13],
      this.startDeck[16],
      this.startDeck[19],
    );
    this.groupThree.push(
      this.startDeck[2],
      this.startDeck[3],
      this.startDeck[8],
      this.startDeck[9],
      this.startDeck[14],
      this.startDeck[15],
      this.startDeck[20],
    );
  }

  chooseGroupOne(): void {
    if (this.gameRound < 2) {
      this.magicDeck = [];
      this.magicDeck = this.groupTwo.concat(this.groupOne).concat(this.groupThree);
      this.gameRound = this.gameRound + 1;
      this.nextRound();
    } else {
      this.magicDeck = [];
      this.magicDeck = this.groupTwo.concat(this.groupOne).concat(this.groupThree);
      this.findMagicCard();
    }

  }
  chooseGroupTwo(): void {
    if (this.gameRound < 2) {
      this.magicDeck = [];
      this.magicDeck = this.groupOne.concat(this.groupTwo).concat(this.groupThree);
      this.gameRound = this.gameRound + 1;
      this.nextRound();
    } else {
      this.magicDeck = [];
      this.magicDeck = this.groupOne.concat(this.groupTwo).concat(this.groupThree);
      this.findMagicCard();
    }
  }
  chooseGroupThree(): void {
    if (this.gameRound < 2) {
      this.magicDeck = [];
      this.magicDeck = this.groupTwo.concat(this.groupThree).concat(this.groupOne);
      this.gameRound = this.gameRound + 1;
      this.nextRound();
    } else {
      this.magicDeck = [];
      this.magicDeck = this.groupTwo.concat(this.groupThree).concat(this.groupOne);
      this.findMagicCard();
    }
  }

  nextRound(): void {
    this.groupOne = [];
    this.groupTwo = [];
    this.groupThree = [];
    this.startDeck = this.magicDeck;
    this.dealDeck();
  }

  findMagicCard(): void {
    this.gameStatus = 'final magic';
    this.magicCard = this.magicDeck[10].cardName;
    console.log(this.magicCard, this.magicDeck);
  }

  newGame(): void {
    window.location.reload();
  }
}
