<div class="jumbotron-footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <p>{{ appTitle }}, {{ year }} <a target="_blank" href="https://www.thegreenedgellc.com/terms"> | Terms</a></p>
                <p>Design by: © <a target="_blank" href="https://www.thegreenedgellc.com" target="_blank"  style="color: var(--thegreenedge);">The Green Edge LLC</a></p> 
            </div>
        </div>
    </div>
</div>
