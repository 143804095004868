export const deck = [
    {
        cardName: 'ace-clubs',
    },
    {
        cardName: 'ace-diamonds',
    },
    {
        cardName: 'ace-hearts',
    },
    {
        cardName: 'ace-spades',
    },
    {
        cardName: 'two-clubs',
    },
    {
        cardName: 'two-diamonds',
    },
    {
        cardName: 'two-hearts',
    },
    {
        cardName: 'two-spades',
    },
    {
        cardName: 'three-clubs',
    },
    {
        cardName: 'three-diamonds',
    },
    {
        cardName: 'three-hearts',
    },
    {
        cardName: 'three-spades',
    },
    {
        cardName: 'four-clubs',
    },
    {
        cardName: 'four-diamonds',
    },
    {
        cardName: 'four-hearts',
    },
    {
        cardName: 'four-spades',
    },
    {
        cardName: 'five-clubs',
    },
    {
        cardName: 'five-diamonds',
    },
    {
        cardName: 'five-hearts',
    },
    {
        cardName: 'five-spades',
    },
    {
        cardName: 'six-clubs',
    },
    {
        cardName: 'six-diamonds',
    },
    {
        cardName: 'six-hearts',
    },
    {
        cardName: 'six-spades',
    },
    {
        cardName: 'seven-clubs',
    },
    {
        cardName: 'seven-diamonds',
    },
    {
        cardName: 'seven-hearts',
    },
    {
        cardName: 'seven-spades',
    },
    {
        cardName: 'eight-clubs',
    },
    {
        cardName: 'eight-diamonds',
    },
    {
        cardName: 'eight-hearts',
    },
    {
        cardName: 'eight-spades',
    },
    {
        cardName: 'nine-clubs',
    },
    {
        cardName: 'nine-diamonds',
    },
    {
        cardName: 'nine-hearts',
    },
    {
        cardName: 'nine-spades',
    },
    {
        cardName: 'ten-clubs',
    },
    {
        cardName: 'ten-diamonds',
    },
    {
        cardName: 'ten-hearts',
    },
    {
        cardName: 'ten-spades',
    },
    {
        cardName: 'jack-clubs',
    },
    {
        cardName: 'jack-diamonds',
    },
    {
        cardName: 'jack-hearts',
    },
    {
        cardName: 'jack-spades',
    },
    {
        cardName: 'queen-clubs',
    },
    {
        cardName: 'queen-diamonds',
    },
    {
        cardName: 'queen-hearts',
    },
    {
        cardName: 'queen-spades',
    },
    {
        cardName: 'king-clubs',
    },
    {
        cardName: 'king-diamonds',
    },
    {
        cardName: 'king-hearts',
    },
    {
        cardName: 'king-spades',
    },
];
