<div class="jumbotron-deck" *ngIf="gameStatus === 'Choose a card'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h2>In your head</h2>
                <h1>Choose a card!</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="carasel">
                    <div class="widget" *ngFor="let card of startDeck">
                        <img src="/assets/img/deck/{{ card.cardName }}.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <p>You can scroll for additional cards</p>
                <button (click)="dealDeck()">Chose a card? Select Me!</button>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-group" *ngIf="gameStatus === 'Choose a group'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h2>Find your card</h2>
                <h1>Which group is your card in?</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="carasel">
                    <div (click)="chooseGroupOne()">
                        <div class="widget" *ngFor="let card of groupOne">
                            <img src="/assets/img/deck/{{ card.cardName }}.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="carasel">
                    <div (click)="chooseGroupTwo()">
                        <div class="widget" *ngFor="let card of groupTwo">
                            <img src="/assets/img/deck/{{ card.cardName }}.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="carasel">
                    <div (click)="chooseGroupThree()">
                        <div class="widget" *ngFor="let card of groupThree">
                            <img src="/assets/img/deck/{{ card.cardName }}.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <p>Choose which group your can is in. (3 Times)</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-magic" *ngIf="gameStatus === 'final magic'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h2>21 Magic!</h2>
                <h1>Is this your card?!</h1>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <img src="/assets/img/deck/{{ magicCard }}.jpg" alt="">
                <button (click)="newGame()">New Game</button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>