<div class="jumbotron-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <i class="fas fa-magic"></i>
                <h1>21</h1>
                <p>Magic</p>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>