import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.playAudio();
    }, 1000);
  }

  playAudio(){
    let audio = new Audio();
    audio.src = "/assets/audio/jingle.mp3";
    audio.load();
    audio.play();
    setTimeout(() => {
        this.router.navigate(['/home']);
    }, 3000);
  }

}
