import { NgModule } from '@angular/core';

// ANIMATIONS
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// WEBSITE DIALOG BOX
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
    imports: [
        BrowserAnimationsModule,
        MatDialogModule,
    ],
    exports: [
        BrowserAnimationsModule,
        MatDialogModule,
    ],
})
export class CustomMaterialModule { }
